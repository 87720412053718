<template>
  <div class="collectionshop-style">
    <div
      class="box1"
    >
      <div class="box1-pos" :style="{
        backgroundImage: `url(${$http.imgurl + info.backgroundimage})`,
      }"></div>
      <img :src="$http.imgurl + info.collectionimage" alt="" class="b1img" />
    </div>
    <div v-loading="isloading" class="box2 between-start">
      <div class="left">
        <div class="b2-t1 break">{{ info.collectionname }}</div>
        <div class="left-b1 center-center">
          <div>{{info.address?.slice(0,6)}}...{{info.address?.slice(info.address?.length - 4)}}</div>
          <img src="@/assets/img/copy1.png" alt="" @click="copy(info.address)" class="left-img" />
        </div>
      </div>
      <div class="right end-center">
        <img src="@/assets/img/img20.png" alt="" v-if="info.is_collect == 1" @click="collectNft" class="right-img" />
        <img v-else src="@/assets/img/img21.png" alt="" @click="collectNft" class="right-img" />
        <div class="right-t1"></div>
        <img src="@/assets/img/img68.png" v-if="info.link" alt="" class="right-img" @click="goOutLink(info.link)" />
        <img src="@/assets/img/img3.png" v-if="info.twitter" alt="" class="right-img" @click="goOutLink(info.twitter)" />
        <img src="@/assets/img/img67.png" v-if="info.telegram" alt="" class="right-img" @click="goOutLink(info.telegram)" />
        <img src="@/assets/img/img66.png" v-if="info.discard" alt="" class="right-img" @click="goOutLink(info.discard)" />
      </div>
      <!-- <div class="b2-t1">{{ info.collectionname }}</div>
      <div class="b2-t2 center-center" @click="collectNft">
        <img
          v-if="info.is_collect == 1"
          src="@/assets/img/img20.png"
          alt=""
          class="b2img"
        />
        <img v-else src="@/assets/img/img21.png" alt="" class="b2img" />
        <div class="b2-t2-t1">
          {{ info.is_collect == 1 ? $t('text.t78') : $t('text.t79') }}
        </div>
      </div>
      <div class="b2-t3">{{$t('text.t80')}}：{{ info.collectionintro }}</div> -->
    </div>
    <div class="box5 evenly-center">
      <div class="box5-b1">
        <div class="tt1">{{$t('text.t293')}}</div>
        <div class="tt2">{{info.collections}}</div>
      </div>
      <div class="box5-b1">
        <div class="tt1">{{$t('text.t294')}}</div>
        <div class="tt2">{{info.holders}}</div>
      </div>
      <div class="box5-b1">
        <div class="tt1">{{$t('text.t295')}}</div>
        <div class="tt2">{{Number(info.min_price).toFixed(4)}}</div>
      </div>
      <div class="box5-b1">
        <div class="tt1">{{$t('text.t296')}}</div>
        <div class="tt2">{{Number(info.total_price).toFixed(4)}}</div>
      </div>
    </div>
    <div class="box6">{{$t('text.t80')}}：{{ info.collectionintro }}</div>
    <div class="box3">
      <van-search
        background="#fff"
        shape="round"
        v-model="value"
        :placeholder="$t('text.t81')"
        @search="onSearch"
      />
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('text.t39')"
      :offset="10"
      @load="getlist"
    >
      <div class="box4 start-center">
        <div class="item center-center" v-for="item in list" :key="item.id">
          <div class="itembox" @click="item.onType=='1'?goAhead(`/nftdetail?orderno=${item.orderno}`):goAhead(`/nftdetail?id=${item.id}&isup=1`)">
            <div class="ib-imgbox">
              <img
                v-if="item.status == 1"
                :src="item.image"
                alt=""
                class="ib-img"
              />
              <img
                v-else-if="item.status == 0"
                :src="language=='1'?$concatImg('img60'):$concatImg('img61')"
                alt=""
                class="ib-img"
              />
              <img
                v-else-if="item.status == 2"
                :src="language=='1'?$concatImg('img62'):$concatImg('img63')"
                alt=""
                class="ib-img"
              />
            </div>
            <div class="ib-info">
              <div class="ib-t1">{{ item.title }}#{{ item.tokenid }}</div>
              <div class="ib-t2">{{$t('text.t44')}}：</div>
              <div class="ib-t3 start-center">
                <img src="@/assets/img/img17.png" alt="" class="ib-t3-img" />
                <div class="ib-t3-t1" v-if="item.onType == '1'">{{item.price}}</div>
                <div class="ib-t3-t1" v-else>---</div>
              </div>
              <div class="ib-t4 start-center">
                <img src="@/assets/img/img18.png" alt="" class="ib-t4-img" />
                <div class="ib-t4-t1">{{ item.heat }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      value: "",
      id: "",
      info: {},
      isloading: false,
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
      type:''
    };
  },
  computed: {
    ...mapState(["language"]),
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.isup ? 1 : ''
    this.getdetail();
  },
  mounted() {},
  methods: {
    onSearch() {
      this.loading = false;
      this.finished = false;
      this.page = 1;
      this.list = [];
    },
    getNewlist(){
      let arg = {
        page: this.page,
        limit: this.limit,
        collection_id: this.id,
        title: this.value,
        language: this.language,
      };
      this.$http.get("/collections/collectOrderList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        collection_id: this.id,
        title: this.value,
        language: this.language,
      };
      this.$http.get("/collections/collectListEnd", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
    collectNft() {
      this.isloading = true;
      this.$http
        .post("/collection/enshrine", {
          id: this.id,
          language: this.language,
        })
        .then((res) => {
          this.$toast(res.msg);
          this.getdetail();
          this.$bus.$emit("upRankinglist", this.id);
        })
        .catch(() => {
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
        });
    },
    getdetail() {
      this.$http
        .get("/collection/collectionDetail", {
          id: this.id,
          language: this.language,
        })
        .then((res) => {
          this.info = res.data;
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.isloading = false;
          }, 1000);
        });
    },
    goOutLink(url){
      window.location.href = url
    },
  },
};
</script>

<style lang="less">
.collectionshop-style {
  .box1 {
    height: 130px;
    position: relative;
    margin-bottom: 8px;
    .box1-pos{
      height: 119px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .b1img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      position: absolute;
      left: 20%;
      transform: translateX(-50%);
      bottom: 0;
    }
  }
  .box2 {
    margin-bottom: 13px;
    .left{
      width: 40%;
      .left-b1{
        font-size: 12px;
        font-weight: 500;
        color: #909090;
      }
      .left-img{
        width: 12px;
        height: 12px;
        margin-left: 10px;
      }
    }
    .right{
      padding-right: 17px;
      .right-img{
        height: 15px;
        width: auto;
        margin-left: 16px;
      }
      .right-t1{
        width: 1px;
        height: 15px;
        background: #909090;
        margin-left: 16px;
      }
    }
    .b2-t1 {
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 10px;
      padding: 0 20px;
    }
    .b2-t2 {
      height: 19px;
      border: 1px solid #323232;
      border-radius: 12px;
      padding: 0 11px 0 8px;
      margin-bottom: 10px;
      .b2img {
        width: 11px;
        height: 10px;
        margin-right: 5px;
      }
      .b2-t2-t1 {
        font-size: 9px;
        font-weight: bold;
        color: #323232;
      }
    }
    .b2-t3 {
      font-size: 9px;
      font-weight: 500;
      color: #323232;
    }
  }
  .box5{
    text-align: center;
    margin-bottom: 15px;
    .tt1{
      font-size: 12px;
      font-weight: 500;
      color: #909090;
      margin-bottom: 5px;
    }
    .tt2{
      font-size: 12px;
      font-weight: bold;
      color: #323232;
    }
  }
  .box6{
    width: 332px;
    margin: auto;
    font-size: 10px;
    font-weight: 500;
    color: #323232;
    margin-bottom: 15px;
    text-align: center;
  }
  .box3 {
    width: 375px;
    margin: auto;
    .van-search__content--round {
      border: 1px solid #000;
    }
    .van-search__content {
      background-color: #fff;
    }
  }
  .box4 {
    margin: auto;
    padding: 0 11px;
    flex-wrap: wrap;
    .item {
      width: 176px;
      margin-bottom: 15px;
      .itembox {
        box-shadow: 0 0 6px rgba(50, 50, 50, 0.1);
        width: 150px;
        border-radius: 10px;
        overflow: hidden;
        .ib-imgbox {
          width: 150px;
          height: 159px;
          overflow: hidden;
          .ib-img {
            width: 100%;
            height: auto;
          }
        }
        .ib-info {
          color: #323232;
          font-weight: bold;
          padding: 5px 10px;
          .ib-t1 {
            font-size: 12px;
            margin-bottom: 5px;
          }
          .ib-t2 {
            font-size: 9px;
            margin-bottom: 4px;
          }
          .ib-t3 {
            margin-bottom: 8px;
            .ib-t3-img {
              width: 9px;
              height: 8px;
              margin-right: 5px;
            }
            .ib-t3-t1 {
              font-size: 12px;
            }
          }
          .ib-t4 {
            .ib-t4-img {
              width: 12px;
              height: 10px;
              margin-right: 6px;
            }
            .ib-t4-t1 {
              font-size: 9px;
            }
          }
        }
      }
    }
  }
}
</style>